import React from 'react'

import Layout from '../components/layout'
import Content404 from '../components/content-404'
import SEO from '../components/seo'

const Page404 = props => {
  const title = '404'

  return (
    <Layout title={title} {...props}>
      <SEO title={title} />
      {<Content404 title={title} />}
    </Layout>
  )
}

export default Page404
