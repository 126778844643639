import React from 'react'
import Link from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    minHeight: 'calc(100vh - 150px)' // - top bar and bottom nav
  },
  title: {
    fontSize: theme.typography.pxToRem(96),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1.3)
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: theme.typography.pxToRem(0.2),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2.5)
  },
  button: {
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(1.25),
    padding: theme.spacing(1.3) + 'px ' + theme.spacing(5) + 'px'
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: 'none'
  }
}))

const content404 = props => {
  let { title, text, btntext, btnlink } = props
  const classes = useStyles()

  title = title ?? '404'
  text = text ?? 'This page was not found.'
  btntext = btntext ?? 'Go home'
  btnlink = btnlink ?? '/'

  return (
    <Box className={classes.root}>
      <div>
        <Typography variant="h1" component="h1" className={classes.title}>
          {title}
        </Typography>

        <Typography className={classes.text}>{text}</Typography>

        <Button variant="contained" color="primary" component="span" className={classes.button}>
          <Link to={btnlink} className={classes.link}>
            {btntext}
          </Link>
        </Button>
      </div>
    </Box>
  )
}

export default content404
